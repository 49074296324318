<template>
  <v-app>
    <v-main class="grey lighten-2">
      <div style="height: 100vh;">
        <div class="d-flex flex-column justify-content-around align-items-center" style="height: 100%;">
          <div class="container text-center p-0">
            <img :src="require('../images/umoja.png')" class="w-25" data-aos="flip-up" data-aos-duration="1000" />
            
            <div v-if="$store.state.dejavu==null">
              <h3 class="mb-5 black--text">
                Thank you for your patience. Your test is currently being submitted.
              </h3>
            </div>
            
            <div v-else>
              <h3 class="mb-5 black--text">Please hold on, your test will commence shortly</h3>
            </div>
            
            <v-progress-circular :size="100" color="orange darken-3" indeterminate></v-progress-circular>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data() {
      return {
        interval: {},
        value: 0,
      }
    },
    beforeUnmount() {
      clearInterval(this.interval)
    },
    created() {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0)
        }
        this.value += 10
      }, 500)
      console.log("Home:");
      console.log(this.$store.state.ye);
      this.loadThisPage();
    },
    methods: {
      loadThisPage() {
        var interval = 10000;
        var destination = "testUmoja";

        if (this.$store.state.dejavu == false) {
          interval = 5000;
          destination = "rulesTest";
        }else if (this.$store.state.dejavu == null) {
          interval = 10000;
          destination = "endTest";
        }else if (this.$store.state.dejavu == "cheating") {
          interval = 5000;
          destination = "cheatingPage";
        }

        setTimeout(() => {
          this.$router.push('/' + destination);
        }, interval);
      }
    }
  }
</script>